<template>
  <AlertError v-if="errors.length" :error="errors[0]" />
  <p class="text-uppercase mt-3 mb-5">
    Accedi alla tua area
  </p>
  <div>
    <form @keyup.enter="validate()">
      <div class="mb-3">
        <label for="email" class="form-label ms-2 mb-0">EMAIL</label>
        <input
          id="email"
          type="email"
          class="form-control"
          v-model="data.email"
        >
      </div>
      <div>
        <label for="password" class="form-label ms-2 mb-0">PASSWORD</label>
        <input
          id="password"
          type="password"
          class="form-control"
          v-model="data.password"
        >
      </div>
      <button
        v-if="!isLoading"
        type="button"
        class="btn bg-blue text-uppercase mt-4 w-100"
        @click="validate"
      >
        Accedi
      </button>
      <div class="mt-5">
        <slot v-if="!isLoading" />
      </div>
      <div class="d-flex justify-content-center my-4">
        <SpinnerBlue :loading="isLoading" />
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { AuthData, UserActionTypes } from '@/store/user/store.types';
import router from "@/router";
import SpinnerBlue from "@/components/spinners/SpinnerBlue.vue";
import { spinnerIsLoading } from "@/mixins/spinner.mixin";
import AlertError from "@/components/alerts/AlertError.vue";
import AlertSuccess from "@/components/alerts/AlertSuccess.vue";
import { LoginStatus } from "@/types/LoginStatusEnum";

export default defineComponent({
  name: "LoginForm",
  components: {AlertSuccess, AlertError, SpinnerBlue },
  mixins: [spinnerIsLoading],
  props: {
    changeMode: Function
  },
  data () {
    return {
      data: {
        email: '' as string,
        password: '' as string,
      } as AuthData,
      errors: [] as string[],
      message: '' as string,
    }
  },
  methods: {
    validate() {
      this.errors = [];

      if (!this.data.email) {
        return this.errors.push('Campo email obbligatorio.');
      }

      if (!this.data.password) {
        return this.errors.push('Campo password obbligatorio');
      }

      this.login();
    },
    async login() {
      this.startLoading();

      const login: any = await this.$store.dispatch(UserActionTypes.SET_TOKEN, this.data);

      const response = await this.$store.dispatch(UserActionTypes.SET_USER);
      this.stopLoading();


      if (login === LoginStatus.NotVerified) return this.errors.push('Email non verificata');
      if (response === LoginStatus.Invalid) return this.errors.push('Credenziali non corrette');
      if (login === LoginStatus.Success && response === LoginStatus.Success) return router.push({ name: 'Dashboard' });
    },

  }
})
</script>

<style scoped>
  label {
    font-size: 15px;
    color: #173759;
    font-weight: 700;
  }

  input,
  button {
    border-radius: 16px;
  }

  input {
    height: 34px;
  }
</style>