
import { defineComponent, ref } from 'vue';
import { User } from "@/types/User";
import LoginForm from '@/components/forms/auth/LoginForm.vue';
import RegisterForm from '@/components/forms/auth/RegisterForm.vue';
import AlertSuccess from '@/components/alerts/AlertSuccess.vue';

export default defineComponent({
  name: 'Login',
  components: {
    AlertSuccess,
    RegisterForm,
    LoginForm
  },
  setup() {
    let registerMode = ref<boolean>(false);
    let message = ref<string>('');

    function changeMode(): boolean {
      return registerMode.value = !registerMode.value;
    }

    function userRegistered(user: User) {
      const { name, email } = user;

      message.value = `Gentile ${name}, abbiamo inoltrato un'email al tuo indirizzo "${hideEmail(email)}". Conferma la tua email per accedere ai servizi. `;
      changeMode();
    }

    function hideEmail(email: string) {
      const emailToHide = email.split('@');

      return `${emailToHide[0].replace(/.(?=.{3,}$)/g, '*')}@${emailToHide[1]}`;
    }


    return {
      registerMode,
      message,
      changeMode,
      userRegistered
    }
  }
})
