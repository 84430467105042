<template>
  <div class="bg-full">
    <img id="bg-login" src="@/assets/images/bg-login.jpg" alt="">
    <div class="login bg-white center p-4" style="z-index: 9">
      <AlertSuccess :message="message" />
      <LoginForm v-if="!registerMode">
        <p
          @click="changeMode"
          class="register m-auto py-2 px-2 c-blue fw-bolder cursor-pointer"
        >
          Registrati
        </p>
      </LoginForm>
      <RegisterForm
        v-else
        @registered="userRegistered"
      >
        <p
          @click="changeMode"
          class="register m-auto py-2 px-2 c-blue fw-bolder cursor-pointer"
        >
          Accedi
        </p>
      </RegisterForm>
    </div>
  </div>

</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { User } from "@/types/User";
import LoginForm from '@/components/forms/auth/LoginForm.vue';
import RegisterForm from '@/components/forms/auth/RegisterForm.vue';
import AlertSuccess from '@/components/alerts/AlertSuccess.vue';

export default defineComponent({
  name: 'Login',
  components: {
    AlertSuccess,
    RegisterForm,
    LoginForm
  },
  setup() {
    let registerMode = ref<boolean>(false);
    let message = ref<string>('');

    function changeMode(): boolean {
      return registerMode.value = !registerMode.value;
    }

    function userRegistered(user: User) {
      const { name, email } = user;

      message.value = `Gentile ${name}, abbiamo inoltrato un'email al tuo indirizzo "${hideEmail(email)}". Conferma la tua email per accedere ai servizi. `;
      changeMode();
    }

    function hideEmail(email: string) {
      const emailToHide = email.split('@');

      return `${emailToHide[0].replace(/.(?=.{3,}$)/g, '*')}@${emailToHide[1]}`;
    }


    return {
      registerMode,
      message,
      changeMode,
      userRegistered
    }
  }
})
</script>

<style scoped>
  .bg-full {
    position: relative;
    height: calc(100vh - 80px);
    background-color: #bfbfbf;
    overflow: hidden;
  }

  #bg-login {
    width: 102%;
    height: 100%;
    overflow: hidden;
    -o-object-fit: cover;
    object-fit: cover;
    left: -10px;
    position: relative;
  }

  .login {
    position: absolute;
    width: 380px;
    min-height: 490px;
    border-radius: 55px;
  }

  .register {
    width: fit-content;
    width: -moz-fit-content;
  }

  p.register:hover {
    background-color: #173759;
    color: #fff;
    border-radius: 0.9rem;
  }


  @media screen and (min-width: 992px) {
    .bg-full {
      height: calc(100vh - 96px);
    }
  }
</style>