
import { defineComponent } from 'vue';
import { register } from "@/api/auth.api";
import { UserRegister } from "@/types/User";
import AlertError from "@/components/alerts/AlertError.vue";
import { spinnerIsLoading } from "@/mixins/spinner.mixin";
import SpinnerBlue from "@/components/spinners/SpinnerBlue.vue";

export default defineComponent({
  name: "RegisterForm",
  components: {SpinnerBlue, AlertError },
  emits: ['registered'],
  mixins: [spinnerIsLoading],
  data() {
    return {
      data: {
        name: '' as string,
        surname: '' as string,
        email: '' as string,
        email_confirmation: '' as string,
        password: '' as string,
      },
      error: '' as string
    }
  },
  methods: {
    resetError(): void {
      this.error = '';
    },
    async register() {
      this.startLoading();
      this.resetError();

      const response = await register(this.data as UserRegister);
      this.stopLoading();

      if (!response.data) {
        // @ts-ignore
        return this.setError(response.response.data.errors);
      }

      return this.$emit('registered', response.data);
    },
    setError(errors: any) {
      const { email, password, name, surname } = errors;

      if (email.length > 1) return this.error = email[1];
      if (email) return this.error = email[0];
      if (password) return this.error = password[0];
      if (name) return this.error = name[0];
      if (surname) return this.error = surname[0];

      return this.error = 'Si è verificato un errore...';
    }
  }
})
