<template>
  <p class="text-uppercase mt-3 mb-5">
    Registrati per accedere
  </p>
  <AlertError :error="error" />
  <div>
    <form>
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="name" class="form-label ms-2 mb-0">NOME</label>
          <input
              id="name"
              type="text"
              class="form-control"
              v-model="data.name"
          >
        </div>
        <div class="col-md-6">
          <label for="surname" class="form-label ms-2 mb-0">COGNOME</label>
          <input
              id="surname"
              type="text"
              class="form-control"
              v-model="data.surname"
          >
        </div>
      </div>
      <div class="mb-3">
        <label for="email" class="form-label ms-2 mb-0">EMAIL</label>
        <input
            id="email"
            type="email"
            class="form-control"
            v-model="data.email"
        >
      </div>
      <div class="mb-3">
        <label for="repeat-email" class="form-label ms-2 mb-0">RIPETI L'EMAIL</label>
        <input
            id="repeat-email"
            type="email"
            class="form-control"
            v-model="data.email_confirmation"
        >
      </div>
      <div>
        <label for="password" class="form-label ms-2 mb-0">PASSWORD</label>
        <input
            id="password"
            type="password"
            class="form-control"
            v-model="data.password"
        >
      </div>
      <button
          v-if="!isLoading"
          type="button"
          class="btn bg-blue text-uppercase mt-4 w-100"
          @click="register"
      >
        Registrati
      </button>
      <div class="mt-5">
        <slot v-if="!isLoading" />
      </div>
      <div class="d-flex justify-content-center my-4">
        <SpinnerBlue :loading="isLoading" />
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { register } from "@/api/auth.api";
import { UserRegister } from "@/types/User";
import AlertError from "@/components/alerts/AlertError.vue";
import { spinnerIsLoading } from "@/mixins/spinner.mixin";
import SpinnerBlue from "@/components/spinners/SpinnerBlue.vue";

export default defineComponent({
  name: "RegisterForm",
  components: {SpinnerBlue, AlertError },
  emits: ['registered'],
  mixins: [spinnerIsLoading],
  data() {
    return {
      data: {
        name: '' as string,
        surname: '' as string,
        email: '' as string,
        email_confirmation: '' as string,
        password: '' as string,
      },
      error: '' as string
    }
  },
  methods: {
    resetError(): void {
      this.error = '';
    },
    async register() {
      this.startLoading();
      this.resetError();

      const response = await register(this.data as UserRegister);
      this.stopLoading();

      if (!response.data) {
        // @ts-ignore
        return this.setError(response.response.data.errors);
      }

      return this.$emit('registered', response.data);
    },
    setError(errors: any) {
      const { email, password, name, surname } = errors;

      if (email.length > 1) return this.error = email[1];
      if (email) return this.error = email[0];
      if (password) return this.error = password[0];
      if (name) return this.error = name[0];
      if (surname) return this.error = surname[0];

      return this.error = 'Si è verificato un errore...';
    }
  }
})
</script>

<style scoped>
  label {
    font-size: 15px;
    color: #173759;
    font-weight: 700;
  }

  input {
    height: 34px;
  }

  input,
  button {
    border-radius: 16px;
  }
</style>