
import { defineComponent } from 'vue';
import { AuthData, UserActionTypes } from '@/store/user/store.types';
import router from "@/router";
import SpinnerBlue from "@/components/spinners/SpinnerBlue.vue";
import { spinnerIsLoading } from "@/mixins/spinner.mixin";
import AlertError from "@/components/alerts/AlertError.vue";
import AlertSuccess from "@/components/alerts/AlertSuccess.vue";
import { LoginStatus } from "@/types/LoginStatusEnum";

export default defineComponent({
  name: "LoginForm",
  components: {AlertSuccess, AlertError, SpinnerBlue },
  mixins: [spinnerIsLoading],
  props: {
    changeMode: Function
  },
  data () {
    return {
      data: {
        email: '' as string,
        password: '' as string,
      } as AuthData,
      errors: [] as string[],
      message: '' as string,
    }
  },
  methods: {
    validate() {
      this.errors = [];

      if (!this.data.email) {
        return this.errors.push('Campo email obbligatorio.');
      }

      if (!this.data.password) {
        return this.errors.push('Campo password obbligatorio');
      }

      this.login();
    },
    async login() {
      this.startLoading();

      const login: any = await this.$store.dispatch(UserActionTypes.SET_TOKEN, this.data);

      const response = await this.$store.dispatch(UserActionTypes.SET_USER);
      this.stopLoading();


      if (login === LoginStatus.NotVerified) return this.errors.push('Email non verificata');
      if (response === LoginStatus.Invalid) return this.errors.push('Credenziali non corrette');
      if (login === LoginStatus.Success && response === LoginStatus.Success) return router.push({ name: 'Dashboard' });
    },

  }
})
